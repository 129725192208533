// src/components/ModelVisualization.js

import React, { useEffect, useRef, useState } from 'react';
import { normalize as normalizePath, join as joinPath } from 'path-browserify';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { VertexNormalsHelper } from 'three/examples/jsm/helpers/VertexNormalsHelper';
import { Alert, AlertDescription } from './ui/alert';
import { Button } from "./ui/button";
import { Card } from "./ui/card";
import { Slider } from "./ui/slider";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { 
    Eye, 
    Grid, 
    LayoutGrid, 
    Ruler, 
    Layers,
    Command,
    Star,
    ArrowLeft,
    ArrowRight,
    Tag
} from 'lucide-react';
import axios from 'axios'; // For handling metadata API calls

const CONFIG = {
    MODELS_BASE_PATH: '/models',
    METADATA_SUFFIX: '_metadata.json',
    API_ENDPOINTS: {
        MODELS: '/api/models',
        METADATA: '/api/metadata',
        SAVE_METADATA: '/api/save-metadata'
    },
    MAX_PATH_LENGTH: 260
};

const api = axios.create({
    baseURL: '/',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Custom path validation function
const isValidPath = (path) => {
    // Basic checks
    if (typeof path !== 'string' || path.length > CONFIG.MAX_PATH_LENGTH) {
        console.warn(`Invalid path type or length: ${path}`);
        return false;
    }

    // Normalize the path
    const normalized = normalizePath(path);

    // Ensure the path starts with the base path and doesn't contain '..'
    if (!normalized.startsWith(CONFIG.MODELS_BASE_PATH + '/') || normalized.includes('..')) {
        console.warn(`Path does not start with base path or contains traversal: ${path}`);
        return false;
    }

    // Additional checks can be added here if necessary
    return true;
};

// ModelAnalytics Class to Analyze Model Statistics
class ModelAnalytics {
    constructor(model) {
        this.stats = this.analyzeModel(model);
    }

    analyzeModel(model) {
        let vertexCount = 0;
        let triangleCount = 0;
        let materialCount = new Set();
        let textureCount = new Set();
        let boundingBox = new THREE.Box3();
        let geometryCount = 0;
        let meshCount = 0;

        model.traverse((child) => {
            if (child.isMesh) {
                meshCount++;
                geometryCount++;
                const geometry = child.geometry;
                if (geometry.index !== null) {
                    triangleCount += geometry.index.count / 3;
                } else if (geometry.attributes.position) {
                    triangleCount += geometry.attributes.position.count / 3;
                }
                if (geometry.attributes.position) {
                    vertexCount += geometry.attributes.position.count;
                }
                if (child.material) {
                    const materials = Array.isArray(child.material) ? child.material : [child.material];
                    materials.forEach(material => {
                        materialCount.add(material.uuid);
                        if (material.map) textureCount.add(material.map.uuid);
                    });
                }
                boundingBox.expandByObject(child);
            }
        });

        const dimensions = new THREE.Vector3();
        boundingBox.getSize(dimensions);

        return {
            vertices: vertexCount,
            triangles: Math.floor(triangleCount),
            materials: materialCount.size,
            textures: textureCount.size,
            meshes: meshCount,
            geometries: geometryCount,
            dimensions: {
                width: Math.round(dimensions.x * 1000) / 1000,
                height: Math.round(dimensions.y * 1000) / 1000,
                depth: Math.round(dimensions.z * 1000) / 1000
            },
            volume: Math.round(dimensions.x * dimensions.y * dimensions.z * 1000) / 1000
        };
    }
}

// ModelManager Class to Handle Model Loading, Viewing, and Metadata
class ModelManager {
    constructor(scene, camera, controls) {
        this.scene = scene;
        this.camera = camera;
        this.controls = controls;
        this.loader = new GLTFLoader();
        this.models = new Map();
        this.modelOrder = [];
        this.watchInterval = null;
        this.SPACING = 2;
        this.lastModelList = [];
        this.baseModelSize = 1;
        this.originalMaterials = new WeakMap();
        this.targetedModel = null;
        this.helpers = new Map();
        this.additionalMeshes = new Map();
        this.viewSettings = {
            edgeColor: new THREE.Color(0x00ff00),
            edgeWidth: 1,
            vertexColor: new THREE.Color(0x00ff00),
            vertexSize: 0.01
        };
        this.currentViewMode = 'textured';
        this.modelCache = new Map();
        this.lastCameraPosition = null;
        this.lastControlsTarget = null;
        this.metadataCache = new Map();
        this.config = {
            MODELS_PATH: '/models',
            METADATA_SUFFIX: '_metadata.json',
            MAX_PATH_LENGTH: 260
        };
    }

    // Get Metadata for a Given Model Path
    getMetadata(modelPath) {
        return this.metadataCache.get(modelPath) || { rating: 0, tags: [] };
    }

    // Clear Helpers from the Scene
    clearHelpers(model) {
        const helpers = this.helpers.get(model);
        if (helpers) {
            helpers.forEach(helper => {
                if (helper.geometry) helper.geometry.dispose();
                if (helper.material) helper.material.dispose();
                this.scene.remove(helper);
            });
            this.helpers.set(model, []);
        }
    }

    async scanModelDirectory() {
        try {
            const response = await api.get(CONFIG.API_ENDPOINTS.MODELS);
            if (!response.data) throw new Error('Invalid response from models API');

            // Filter valid paths instead of mapping to booleans
            const modelList = response.data.filter(path => this.validateModelPath(path));
            console.log('Valid models found:', modelList);

            const newModels = modelList.filter(path => !this.lastModelList.includes(path));
            const removedModels = this.lastModelList.filter(path => !modelList.includes(path));

            console.log('New models:', newModels);
            console.log('Removed models:', removedModels);

            if (newModels.length > 0 || removedModels.length > 0) {
                await this.handleModelChanges(newModels, removedModels);
            }

            this.lastModelList = [...modelList];
            return true;
        } catch (error) {
            console.error('Failed to scan model directory:', error);
            return false;
        }
    }

    async handleModelChanges(newModels, removedModels) {
        this.saveCameraState();

        for (const path of removedModels) {
            this.removeModel(path);
        }

        for (const path of newModels) {
            await this.shiftExistingModels();
            const model = await this.loadModel(path);
            if (model) {
                this.modelOrder.unshift(path);
                this.targetedModel = path;
                model.position.set(0, 0, 0);
                await this.loadMetadata(path);
                this.applyViewModeToModel(path, model);
            }
        }

        if (newModels.length > 0 && this.targetedModel) {
            this.focusCamera();
            this.resetAllViewModes();
            this.setViewMode(this.currentViewMode, this.models.get(this.targetedModel));
        } else {
            this.restoreCameraState();
        }
    }

    // Save Current Camera State
    saveCameraState() {
        this.lastCameraPosition = this.camera.position.clone();
        this.lastControlsTarget = this.controls.target.clone();
    }

    // Restore Camera to Last Saved State
    restoreCameraState() {
        if (this.lastCameraPosition && this.lastControlsTarget) {
            this.camera.position.copy(this.lastCameraPosition);
            this.controls.target.copy(this.lastControlsTarget);
            this.controls.update();
            console.log('Camera state restored');
        }
    }

    // Load a Model and Add to the Scene
    async loadModel(path) {
        try {
            if (typeof path !== 'string') {
                throw new TypeError('Model path must be a string');
            }

            const basePath = path.split('?')[0];
            if (this.models.has(basePath)) {
                console.log(`Model already loaded: ${basePath}`);
                return null;
            }

            const gltf = await this.loader.loadAsync(path);
            if (!gltf || !gltf.scene) throw new Error('Invalid GLTF model');

            const model = gltf.scene;
            const bbox = new THREE.Box3().setFromObject(model);
            const size = new THREE.Vector3();
            bbox.getSize(size);
            const maxDim = Math.max(size.x, size.y, size.z);
            const scale = this.baseModelSize / maxDim;
            model.scale.setScalar(scale);

            bbox.setFromObject(model);
            const center = new THREE.Vector3();
            bbox.getCenter(center);
            model.position.sub(center);

            // Clone Original Materials
            model.traverse(child => {
                if (child.isMesh && child.material) {
                    if (Array.isArray(child.material)) {
                        child.material.forEach(material => {
                            if (material) {
                                const clonedMaterial = material.clone();
                                this.originalMaterials.set(child, clonedMaterial);
                                child.material = new THREE.MeshBasicMaterial({
                                    map: material.map || null,
                                    color: material.map ? 0xffffff : 0xcccccc,
                                    transparent: material.transparent || false,
                                    opacity: material.opacity || 1.0
                                });
                            }
                        });
                    } else {
                        const clonedMaterial = child.material.clone();
                        this.originalMaterials.set(child, clonedMaterial);
                        child.material = new THREE.MeshBasicMaterial({
                            map: child.material.map || null,
                            color: child.material.map ? 0xffffff : 0xcccccc,
                            transparent: child.material.transparent || false,
                            opacity: child.material.opacity || 1.0
                        });
                    }
                }
            });

            this.models.set(basePath, model);
            this.scene.add(model);
            model.position.set(0, 0, 0);
            console.log(`Model loaded and added to scene: ${basePath}`);
            return model;
        } catch (error) {
            console.error(`Failed to load model: ${path}`, error);
            return null;
        }
    }

    // Remove a Model from the Scene
    removeModel(path) {
        const model = this.models.get(path);
        if (model) {
            this.clearHelpers(model);
            this.clearAdditionalMeshes(path);
            
            model.traverse(child => {
                if (child.isMesh) {
                    if (child.geometry) {
                        child.geometry.dispose();
                    }
                    if (child.material) {
                        if (Array.isArray(child.material)) {
                            child.material.forEach(mat => {
                                if (mat) {
                                    if (mat.map) mat.map.dispose();
                                    mat.dispose();
                                }
                            });
                        } else {
                            if (child.material.map) child.material.map.dispose();
                            child.material.dispose();
                        }
                    }
                }
            });

            if (this.helpers.has(model)) { // Changed from path to model
                const helper = this.helpers.get(model); // Changed from path to model
                helper.forEach(h => {
                    if (h.geometry) h.geometry.dispose();
                    if (h.material) h.material.dispose();
                    this.scene.remove(h);
                });
                this.helpers.delete(model); // Changed from path to model
            }

            this.scene.remove(model);
            this.models.delete(path);
            const modelIndex = this.modelOrder.indexOf(path);
            if (modelIndex > -1) {
                this.modelOrder.splice(modelIndex, 1);
            }

            if (this.targetedModel === path) {
                this.targetedModel = this.modelOrder[0] || null;
                if (this.targetedModel) {
                    this.saveCameraState();
                    this.resetAllViewModes();
                    this.setViewMode(this.currentViewMode, this.models.get(this.targetedModel));
                    this.restoreCameraState();
                }
            }

            this.shiftExistingModels();
            console.log(`Model removed from scene: ${path}`);
        }
    }

    // Clear Additional Meshes (e.g., Points, Lines) from the Scene
    clearAdditionalMeshes(path) {
        const meshes = this.additionalMeshes.get(path);
        if (meshes) {
            meshes.forEach(mesh => {
                if (mesh.geometry) mesh.geometry.dispose();
                if (mesh.material) mesh.material.dispose();
                this.scene.remove(mesh);
            });
            this.additionalMeshes.delete(path);
            console.log(`Additional meshes cleared for model: ${path}`);
        }
    }

    // Set the Current View Mode for a Model
    setViewMode(mode, model, settings = {}) {
        if (!model) return;
        
        this.clearHelpers(model);
        const helpers = [];
        
        model.traverse(child => {
            if (child.isMesh && child.geometry) {
                const originalMaterial = this.originalMaterials.get(child);
                child.visible = true;
                
                try {
                    switch (mode) {
                        case 'wireframe':
                            child.material = new THREE.MeshBasicMaterial({
                                wireframe: true,
                                color: settings.edgeColor || this.viewSettings.edgeColor
                            });
                            break;
                            
                        case 'vertices':
                            const vertices = child.geometry.attributes.position;
                            if (vertices) {
                                const geometry = new THREE.BufferGeometry();
                                geometry.setAttribute('position', vertices.clone());
                                const pointsMaterial = new THREE.PointsMaterial({
                                    size: settings.vertexSize || this.viewSettings.vertexSize,
                                    color: settings.vertexColor || this.viewSettings.vertexColor,
                                    sizeAttenuation: true
                                });
                                const points = new THREE.Points(geometry, pointsMaterial);
                                points.position.copy(child.position);
                                points.rotation.copy(child.rotation);
                                points.scale.copy(child.scale);
                                points.matrixAutoUpdate = true;
                                this.scene.add(points);
                                helpers.push(points);
                                child.visible = false;
                            }
                            break;
                            
                        case 'edges':
                            child.material = new THREE.MeshBasicMaterial({
                                color: settings.edgeColor || this.viewSettings.edgeColor,
                                transparent: true,
                                opacity: 0.1
                            });
                            const edges = new THREE.EdgesGeometry(child.geometry);
                            const line = new THREE.LineSegments(
                                edges,
                                new THREE.LineBasicMaterial({ 
                                    color: settings.edgeColor || this.viewSettings.edgeColor
                                })
                            );
                            line.position.copy(child.position);
                            line.rotation.copy(child.rotation);
                            line.scale.copy(child.scale);
                            line.matrixAutoUpdate = true;
                            this.scene.add(line);
                            helpers.push(line);
                            break;
                            
                        case 'normals':
                            if (!child.geometry.attributes.normal) {
                                child.geometry.computeVertexNormals();
                            }
                            child.material = new THREE.MeshBasicMaterial({
                                color: 0x808080
                            });
                            if (child.geometry.attributes.normal && child.geometry.attributes.normal.count > 0) {
                                const normalHelper = new VertexNormalsHelper(child, 0.1, 0x00ff00);
                                normalHelper.update();
                                this.scene.add(normalHelper);
                                helpers.push(normalHelper);
                            }
                            break;
                            
                        case 'shaded':
                            child.material = new THREE.MeshPhongMaterial({
                                color: 0x808080,
                                flatShading: true,
                                side: THREE.DoubleSide
                            });
                            break;
                            
                        default:
                            if (originalMaterial && originalMaterial.map) {
                                child.material = new THREE.MeshBasicMaterial({
                                    map: originalMaterial.map,
                                    color: originalMaterial.color.getHex(),
                                    transparent: originalMaterial.transparent || false,
                                    opacity: originalMaterial.opacity || 1.0
                                });
                            } else {
                                child.material = new THREE.MeshBasicMaterial({
                                    color: 0xcccccc,
                                    transparent: originalMaterial?.transparent || false,
                                    opacity: originalMaterial?.opacity || 1.0
                                });
                            }
                    }
                } catch (error) {
                    console.error(`Error setting view mode ${mode} for mesh:`, error);
                    child.material = new THREE.MeshBasicMaterial({ color: 0xcccccc });
                }
            }
        });
        
        this.helpers.set(model, helpers);
        this.currentViewMode = mode;
        console.log(`View mode set to '${mode}' for model`);
    }

    // Update View Settings and Apply to Current Model
    updateViewSettings(settings) {
        this.viewSettings = { ...this.viewSettings, ...settings };
        if (this.targetedModel) {
            const model = this.models.get(this.targetedModel);
            this.setViewMode(this.currentViewMode, model, this.viewSettings);
            console.log('View settings updated');
        }
    }

    // Focus Camera on the Targeted Model
    focusCamera() {
        if (!this.targetedModel || !this.models.has(this.targetedModel)) return;

        const model = this.models.get(this.targetedModel);
        const bbox = new THREE.Box3().setFromObject(model);
        const center = new THREE.Vector3();
        bbox.getCenter(center);
        center.add(model.position);
        
        const size = new THREE.Vector3();
        bbox.getSize(size);
        const maxDim = Math.max(size.x, size.y, size.z);
        const distance = maxDim * 2;
        
        const offset = new THREE.Vector3(0, distance * 0.3, distance);
        this.camera.position.copy(center).add(offset);
        this.controls.target.copy(center);
        this.controls.update();
        console.log(`Camera focused on model: ${this.targetedModel}`);
    }

    // Analyze a Model and Return Statistics
    analyzeModel(path) {
        const model = this.models.get(path);
        if (!model) return null;
        return new ModelAnalytics(model).stats;
    }

    // Reset All Models to Default View Mode
    resetAllViewModes() {
        this.models.forEach(model => {
            this.setViewMode('textured', model);
        });
        console.log('All models reset to textured view mode');
    }

    // Shift Existing Models to the Right
    async shiftExistingModels() {
        const promises = [];
        this.modelOrder.forEach((path, index) => {
            const model = this.models.get(path);
            if (model) {
                const targetX = (index + 1) * this.SPACING;
                if (model.position.x !== targetX) {
                    promises.push(
                        new Promise(resolve => {
                            model.position.x = targetX;
                            resolve();
                        })
                    );
                }
            }
        });
        await Promise.all(promises);
        console.log('Existing models shifted');
    }

    // Start Watching the Models Directory for Changes
    startWatching(interval = 5000) {
        this.watchInterval = setInterval(() => this.scanModelDirectory(), interval);
        console.log(`Started watching models directory every ${interval}ms`);
    }

    // Stop Watching the Models Directory
    stopWatching() {
        if (this.watchInterval) {
            clearInterval(this.watchInterval);
            this.watchInterval = null;
            console.log('Stopped watching models directory');
        }
    }

    sanitizeMetadataPath(metadataPath) {
        try {
            if (typeof metadataPath !== 'string' || metadataPath.length > this.config.MAX_PATH_LENGTH) {
                console.warn(`Invalid metadata path type or length: ${metadataPath}`);
                return null;
            }
    
            // Normalize path with forward slashes and clean up any double slashes
            const cleaned = metadataPath.replace(/\\/g, '/').replace(/\/+/g, '/');
    
            // Basic path validation
            if (!cleaned.startsWith(this.config.MODELS_PATH + '/')) {
                console.warn(`Metadata path does not start with base path: ${cleaned}`);
                return null;
            }
    
            if (!cleaned.endsWith(this.config.METADATA_SUFFIX)) {
                console.warn(`Metadata path does not end with suffix: ${cleaned}`);
                return null;
            }
    
            // Split path into segments and remove the initial 'models' segment to prevent duplication
            const segments = cleaned.split('/').filter(segment => 
                segment !== '' && 
                segment !== '.' && 
                segment !== '..'
            );
    
            if (segments[0] === 'models') {
                segments.shift(); // Remove 'models' to avoid duplication
            }
    
            // Reconstruct path without duplicating '/models'
            const sanitized = joinPath(this.config.MODELS_PATH, ...segments);
    
            // Final validation
            if (!sanitized.startsWith(this.config.MODELS_PATH) || 
                !isValidPath(sanitized)) {
                console.warn(`Sanitized metadata path is invalid: ${sanitized}`);
                return null;
            }
    
            console.log(`Sanitized metadata path: ${sanitized}`);
            return sanitized;
        } catch (error) {
            console.error('Path sanitization failed:', error);
            return null;
        }
    }

    // Load Metadata for a Given Model Path
    async loadMetadata(modelPath) {
        try {
            const metadataPath = `${modelPath}${this.config.METADATA_SUFFIX}`;
            const sanitizedPath = this.sanitizeMetadataPath(metadataPath);
            
            if (!sanitizedPath) {
                throw new Error(`Invalid metadata path: ${metadataPath}`);
            }

            const response = await axios.get(CONFIG.API_ENDPOINTS.METADATA, {
                params: { path: sanitizedPath },
                validateStatus: status => status === 200
            });

            this.metadataCache.set(modelPath, response.data);
            console.log(`Metadata loaded for model: ${modelPath}`);
            return response.data;
        } catch (error) {
            console.warn(`Metadata not found for ${modelPath}:`, error);
            const defaultMetadata = { rating: 0, tags: [] };
            this.metadataCache.set(modelPath, defaultMetadata);
            return defaultMetadata;
        }
    }
    
    // Save Metadata for a Given Model Path
    async saveMetadata(modelPath, metadata) {
        try {
            const metadataPath = `${modelPath}${this.config.METADATA_SUFFIX}`;
            const sanitizedPath = this.sanitizeMetadataPath(metadataPath);
            
            if (!sanitizedPath) {
                throw new Error(`Invalid metadata path: ${metadataPath}`);
            }

            await axios.post(CONFIG.API_ENDPOINTS.SAVE_METADATA, metadata, {
                params: { path: sanitizedPath },
                validateStatus: status => status === 200
            });

            this.metadataCache.set(modelPath, metadata);
            console.log(`Metadata saved for model: ${modelPath}`);
        } catch (error) {
            console.error(`Failed to save metadata for ${modelPath}:`, error);
            throw error;
        }
    }

    validateModelPath(modelPath) {
        try {
            return isValidPath(modelPath);
        } catch (error) {
            console.error('Model path validation failed:', error);
            return false;
        }
    }

    // Get Previous Model in the Order
    getPreviousModel() {
        const currentIndex = this.modelOrder.indexOf(this.targetedModel);
        if (currentIndex > 0) {
            return this.modelOrder[currentIndex - 1];
        }
        return null;
    }

    // Get Next Model in the Order
    getNextModel() {
        const currentIndex = this.modelOrder.indexOf(this.targetedModel);
        if (currentIndex < this.modelOrder.length - 1) {
            return this.modelOrder[currentIndex + 1];
        }
        return null;
    }

    // Apply Current View Mode to a Specific Model
    applyViewModeToModel(path, model) {
        const metadata = this.getMetadata(path);
        const rating = metadata.rating || 0;
        const tags = metadata.tags || [];
        this.setViewMode(this.currentViewMode, model, this.viewSettings);
        console.log(`Applied view mode '${this.currentViewMode}' to model: ${path}`);
    }
}

const ModelVisualization = () => {
    const [viewMode, setViewMode] = useState('textured');
    const [modelStats, setModelStats] = useState(null);
    const [error, setError] = useState({ message: null, type: null });
    const [isLoading, setIsLoading] = useState(false);
    const [viewSettings, setViewSettings] = useState({
        edgeColor: '#00ff00',
        edgeWidth: 1,
        vertexColor: '#00ff00',
        vertexSize: 0.01
    });
    const [rating, setRating] = useState(0);
    const [filterRating, setFilterRating] = useState(0);
    const [availableTags, setAvailableTags] = useState(['3D Mesh', 'Texture', 'Lighting', 'Animation']);
    const [selectedTags, setSelectedTags] = useState([]);

    const containerRef = useRef(null);
    const rendererRef = useRef(null);
    const sceneRef = useRef(null);
    const cameraRef = useRef(null);
    const controlsRef = useRef(null);
    const modelManagerRef = useRef(null);
    const animationFrameRef = useRef(null);

    const viewModes = [
        { id: 'textured', label: 'Textured', icon: Eye },
        { id: 'wireframe', label: 'Wireframe', icon: Grid },
        { id: 'vertices', label: 'Vertices', icon: Command },
        { id: 'edges', label: 'Edges', icon: LayoutGrid },
        { id: 'normals', label: 'Normals', icon: Ruler },
        { id: 'shaded', label: 'Shaded', icon: Layers }
    ];

    const ratingMarks = [
        { value: 0, label: '0' },
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
    ];

    useEffect(() => {
        const init = async () => {
            if (!containerRef.current) return;
            
            setIsLoading(true);
            try {
                // Initialize Three.js Scene
                const scene = new THREE.Scene();
                scene.background = new THREE.Color(0x333333);
                sceneRef.current = scene;

                const camera = new THREE.PerspectiveCamera(
                    50,
                    containerRef.current.clientWidth / containerRef.current.clientHeight,
                    0.1,
                    1000
                );
                camera.position.set(0, 2, 5);
                cameraRef.current = camera;

                const renderer = new THREE.WebGLRenderer({
                    antialias: true,
                    powerPreference: "high-performance"
                });
                renderer.setSize(containerRef.current.clientWidth, containerRef.current.clientHeight);
                renderer.setPixelRatio(window.devicePixelRatio);
                containerRef.current.appendChild(renderer.domElement);
                rendererRef.current = renderer;

                const controls = new OrbitControls(camera, renderer.domElement);
                controls.enableDamping = true;
                controls.dampingFactor = 0.05;
                controls.minDistance = 2;
                controls.maxDistance = 20;
                controlsRef.current = controls;

                const grid = new THREE.GridHelper(20, 20, 0x555555, 0x555555);
                scene.add(grid);

                const ambientLight = new THREE.AmbientLight(0x404040);
                scene.add(ambientLight);

                const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
                directionalLight.position.set(5, 5, 5);
                scene.add(directionalLight);

                // Initialize ModelManager
                modelManagerRef.current = new ModelManager(scene, camera, controls);
                const scanSuccess = await modelManagerRef.current.scanModelDirectory().catch(error => {
                    throw new Error(`Failed to scan model directory: ${error.message}`);
                });

                if (!scanSuccess) {
                    throw new Error('Unable to fetch model directory');
                }

                modelManagerRef.current.startWatching();

                if (modelManagerRef.current?.targetedModel) {
                    await modelManagerRef.current.loadMetadata(modelManagerRef.current.targetedModel);
                    const metadata = modelManagerRef.current.getMetadata(modelManagerRef.current.targetedModel);
                    setRating(metadata.rating || 0);
                    setSelectedTags(metadata.tags || []);
                }

                // Animation Loop
                const animate = () => {
                    animationFrameRef.current = requestAnimationFrame(animate);
                    controls.update();
                    renderer.render(scene, camera);

                    if (modelManagerRef.current?.targetedModel) {
                        const stats = modelManagerRef.current.analyzeModel(modelManagerRef.current.targetedModel);
                        setModelStats(stats);
                    }
                };
                animate();
            } catch (err) {
                console.error('Initialization failed:', err);
                setError({
                    message: err.message || 'Failed to initialize 3D viewer',
                    type: 'error'
                });
            } finally {
                setIsLoading(false);
            }
        };

        init();

        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
            if (modelManagerRef.current) {
                modelManagerRef.current.stopWatching();
            }
            if (rendererRef.current && containerRef.current) {
                containerRef.current.removeChild(rendererRef.current.domElement);
                rendererRef.current.dispose();
            }
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (rendererRef.current && cameraRef.current && containerRef.current) {
                const width = containerRef.current.clientWidth;
                const height = containerRef.current.clientHeight;
                rendererRef.current.setSize(width, height);
                cameraRef.current.aspect = width / height;
                cameraRef.current.updateProjectionMatrix();
                console.log('Renderer resized');
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (modelManagerRef.current) {
            modelManagerRef.current.modelOrder.forEach(path => {
                const metadata = modelManagerRef.current.getMetadata(path);
                const model = modelManagerRef.current.models.get(path);
                if (model && metadata) {
                    model.visible = metadata.rating >= filterRating;
                }
            });
            console.log(`Filter applied: Models with rating >= ${filterRating}`);
        }
    }, [filterRating]);

    const loadMetadata = async (modelPath) => {
        try {
            await modelManagerRef.current.loadMetadata(modelPath);
            const metadata = modelManagerRef.current.getMetadata(modelPath);
            setRating(metadata.rating || 0);
            setSelectedTags(metadata.tags || []);
            console.log(`Metadata loaded for model: ${modelPath}`);
        } catch (error) {
            console.warn(`Failed to load metadata for ${modelPath}:`, error);
            setRating(0);
            setSelectedTags([]);
        }
    };

    const saveMetadata = async () => {
        if (!modelManagerRef.current?.targetedModel) return;
        
        try {
            const modelPath = modelManagerRef.current.targetedModel;
            const metadata = {
                rating,
                tags: selectedTags
            };
            await modelManagerRef.current.saveMetadata(modelPath, metadata);
            
            setError({
                message: 'Metadata saved successfully',
                type: 'success'
            });
            
            setTimeout(() => {
                setError({ message: null, type: null });
            }, 3000);
            console.log(`Metadata saved for model: ${modelPath}`);
        } catch (err) {
            setError({
                message: err.message || 'Failed to save metadata',
                type: 'error'
            });
            console.error(`Failed to save metadata for model: ${modelManagerRef.current.targetedModel}`, err);
        }
    };

    const handleViewModeChange = (id) => {
        setViewMode(id);
        if (modelManagerRef.current?.targetedModel) {
            const model = modelManagerRef.current.models.get(
                modelManagerRef.current.targetedModel
            );
            modelManagerRef.current.setViewMode(id, model, viewSettings);
            console.log(`View mode changed to '${id}'`);
        }
    };

    const handleRatingChange = (value) => {
        setRating(value);
        saveMetadata();
    };

    const handlePreviousModel = async () => {
        if (modelManagerRef.current) {
            const previousPath = modelManagerRef.current.getPreviousModel();
            if (previousPath) {
                modelManagerRef.current.targetedModel = previousPath;
                modelManagerRef.current.focusCamera();
                await loadMetadata(previousPath);
                const metadata = modelManagerRef.current.getMetadata(previousPath);
                setRating(metadata.rating || 0);
                setSelectedTags(metadata.tags || []);
                modelManagerRef.current.applyViewModeToModel(previousPath, modelManagerRef.current.models.get(previousPath));
                console.log(`Switched to previous model: ${previousPath}`);
            } else {
                console.log('No previous model available');
            }
        }
    };

    const handleNextModel = async () => {
        if (modelManagerRef.current) {
            const nextPath = modelManagerRef.current.getNextModel();
            if (nextPath) {
                modelManagerRef.current.targetedModel = nextPath;
                modelManagerRef.current.focusCamera();
                await loadMetadata(nextPath);
                const metadata = modelManagerRef.current.getMetadata(nextPath);
                setRating(metadata.rating || 0);
                setSelectedTags(metadata.tags || []);
                modelManagerRef.current.applyViewModeToModel(nextPath, modelManagerRef.current.models.get(nextPath));
                console.log(`Switched to next model: ${nextPath}`);
            } else {
                console.log('No next model available');
            }
        }
    };

    const handleTagToggle = async (tag) => {
        const updatedTags = selectedTags.includes(tag)
            ? selectedTags.filter(t => t !== tag)
            : [...selectedTags, tag];
        setSelectedTags(updatedTags);

        if (modelManagerRef.current?.targetedModel) {
            const path = modelManagerRef.current.targetedModel;
            const metadata = modelManagerRef.current.getMetadata(path);
            await modelManagerRef.current.saveMetadata(path, { ...metadata, tags: updatedTags });
            console.log(`Tags updated for model: ${path}`);
        }
    };

    const handleFilterChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value >= 0 && value <= 5) {
            setFilterRating(value);
            console.log(`Filter rating set to: ${value}`);
        }
    };

    return (
        <div className="model-visualization-container relative w-full h-full">
            {/* Loading Indicator */}
            {isLoading && (
                <div className="loading absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                    Loading 3D Viewer...
                </div>
            )}

            {/* Error Alert */}
            {error.message && (
                <div className="absolute top-4 left-1/2 transform -translate-x-1/2 z-30">
                    <Alert variant={error.type === 'success' ? 'default' : 'destructive'}>
                        <AlertDescription>{error.message}</AlertDescription>
                    </Alert>
                </div>
            )}

            {/* Top Controls: View Modes */}
            <div className="top-controls absolute top-2 left-0 right-0 flex justify-center z-20 px-2">
                <div className="flex overflow-x-auto space-x-2 p-1 bg-black/30 backdrop-blur-sm rounded-full">
                    {viewModes.map(({ id, label, icon: Icon }) => (
                        <Button
                            key={id}
                            variant={viewMode === id ? "secondary" : "outline"}
                            size="icon"
                            onClick={() => handleViewModeChange(id)}
                            aria-label={label}
                        >
                            <Icon className="w-5 h-5" />
                        </Button>
                    ))}
                </div>
            </div>

            {/* Bottom Controls: Rating Slider, Navigation, Tags */}
            <div className="bottom-controls absolute bottom-4 left-0 right-0 flex flex-col items-center z-20 px-4">
                {/* Rating Slider */}
                <div className="w-full max-w-md bg-black/30 backdrop-blur-sm rounded-lg p-3 mb-4">
                    <Label htmlFor="rating-slider" className="text-white mb-1 flex items-center">
                        <Star className="w-5 h-5 mr-2 text-yellow-400" /> Rate this Model:
                    </Label>
                    <Slider
                        id="rating-slider"
                        value={[rating]}
                        min={0}
                        max={5}
                        step={1}
                        marks={ratingMarks}
                        showMarks={true}
                        onValueChange={([value]) => handleRatingChange(value)}
                        className="mt-2"
                    />
                </div>

                {/* Navigation Buttons */}
                <div className="flex space-x-4 mb-4">
                    <Button variant="outline" size="icon" onClick={handlePreviousModel} aria-label="Previous Model">
                        <ArrowLeft className="w-5 h-5" />
                    </Button>
                    <Button variant="outline" size="icon" onClick={handleNextModel} aria-label="Next Model">
                        <ArrowRight className="w-5 h-5" />
                    </Button>
                </div>

                {/* Tags */}
                <div className="w-full max-w-md bg-black/30 backdrop-blur-sm rounded-lg p-3">
                    <Label className="text-white mb-2 flex items-center">
                        <Tag className="w-5 h-5 mr-2" /> Tags:
                    </Label>
                    <div className="flex flex-wrap gap-2">
                        {availableTags.map(tag => (
                            <Button
                                key={tag}
                                variant={selectedTags.includes(tag) ? "secondary" : "outline"}
                                size="sm"
                                onClick={() => handleTagToggle(tag)}
                                aria-pressed={selectedTags.includes(tag)}
                            >
                                {tag}
                            </Button>
                        ))}
                    </div>
                </div>
            </div>

            {/* Filter Controls */}
            <div className="filter-controls absolute bottom-20 left-0 right-0 flex justify-center z-20 px-2">
                <div className="flex items-center space-x-2 bg-black/30 backdrop-blur-sm rounded-full p-2">
                    <Star className="w-5 h-5 text-yellow-400" />
                    <Input
                        type="number"
                        min="0"
                        max="5"
                        value={filterRating}
                        onChange={handleFilterChange}
                        className="w-12 text-center"
                        aria-label="Filter by Rating"
                    />
                    <span className="text-white">Stars & Up</span>
                </div>
            </div>

            {/* Model Statistics */}
            {modelStats && (
                <div className="model-info absolute top-16 right-4 z-10 bg-white/90 p-2 rounded-lg shadow-md">
                    <Card>
                        <div className="flex flex-col gap-1 text-sm">
                            <div className="flex items-center justify-between">
                                <span className="font-medium">Triangles:</span>
                                <span>{modelStats.triangles.toLocaleString()}</span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span className="font-medium">Vertices:</span>
                                <span className={viewMode === 'vertices' ? 'text-green-600 font-bold' : ''}>
                                    {modelStats.vertices.toLocaleString()}
                                </span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span className="font-medium">Materials:</span>
                                <span>{modelStats.materials}</span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span className="font-medium">Textures:</span>
                                <span>{modelStats.textures}</span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span className="font-medium">Dimensions:</span>
                                <span>
                                    {modelStats.dimensions.width} × {modelStats.dimensions.height} × {modelStats.dimensions.depth}
                                </span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span className="font-medium">Volume:</span>
                                <span>{modelStats.volume}</span>
                            </div>
                        </div>
                    </Card>
                </div>
            )}

            {/* Search Container */}
            <div className="search-container absolute top-24 left-1/2 transform -translate-x-1/2 z-20 w-full max-w-md px-4">
                <div className="flex items-center space-x-2 bg-black/30 backdrop-blur-sm rounded-full p-2 w-full">
                    <Input
                        type="text"
                        placeholder="Search models..."
                        className="flex-1"
                        onChange={(e) => {
                            const searchTerm = e.target.value.toLowerCase();
                            if (modelManagerRef.current) {
                                modelManagerRef.current.modelOrder.forEach(path => {
                                    const model = modelManagerRef.current.models.get(path);
                                    if (model) {
                                        const metadata = modelManagerRef.current.getMetadata(path);
                                        const tags = metadata.tags || [];
                                        const matchesSearch = path.toLowerCase().includes(searchTerm) ||
                                            tags.some(tag => tag.toLowerCase().includes(searchTerm));
                                        model.visible = matchesSearch && (!filterRating || (metadata.rating >= filterRating));
                                    }
                                });
                                console.log(`Search applied: "${searchTerm}"`);
                            }
                        }}
                    />
                </div>
            </div>

            {/* Coordinates Info */}
            <div className="coordinates-info absolute bottom-4 right-4 z-20 bg-black/30 backdrop-blur-sm rounded-lg p-2">
                <div className="text-white text-sm space-y-1">
                    {cameraRef.current && (
                        <>
                            <div>
                                Camera: ({cameraRef.current.position.x.toFixed(2)}, 
                                {cameraRef.current.position.y.toFixed(2)}, 
                                {cameraRef.current.position.z.toFixed(2)})
                            </div>
                            {controlsRef.current && (
                                <div>
                                    Target: ({controlsRef.current.target.x.toFixed(2)}, 
                                    {controlsRef.current.target.y.toFixed(2)}, 
                                    {controlsRef.current.target.z.toFixed(2)})
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>

            {/* Performance Stats */}
            <div className="performance-stats absolute top-4 left-4 z-20 bg-black/30 backdrop-blur-sm rounded-lg p-2">
                <div className="text-white text-sm space-y-1">
                    {rendererRef.current && (
                        <>
                            <div>
                                FPS: {Math.round(rendererRef.current.info.render.fps)}
                            </div>
                            <div>
                                Triangles: {rendererRef.current.info.render.triangles.toLocaleString()}
                            </div>
                            <div>
                                Draw calls: {rendererRef.current.info.render.calls}
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* Scene Controls */}
            <div className="scene-controls absolute top-16 left-4 z-20 bg-black/30 backdrop-blur-sm rounded-lg p-2">
                <div className="flex flex-col space-y-2">
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => {
                            if (sceneRef.current) {
                                const gridHelper = sceneRef.current.children.find(child => child instanceof THREE.GridHelper);
                                if (gridHelper) {
                                    gridHelper.visible = !gridHelper.visible;
                                    console.log(`Grid visibility toggled: ${gridHelper.visible}`);
                                }
                            }
                        }}
                    >
                        Toggle Grid
                    </Button>
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => {
                            if (modelManagerRef.current?.targetedModel) {
                                modelManagerRef.current.focusCamera();
                            }
                        }}
                    >
                        Reset View
                    </Button>
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => {
                            if (rendererRef.current) {
                                rendererRef.current.info.reset();
                                console.log('Renderer stats reset');
                            }
                        }}
                    >
                        Reset Stats
                    </Button>
                </div>
            </div>

            {/* 3D Scene Container */}
            <div 
                ref={containerRef} 
                className="network-main w-full h-full"
                onContextMenu={(e) => e.preventDefault()}
                // Removed onWheel handler to eliminate passive event listener warning
            />
        </div>
    );
}
    export default ModelVisualization;
    
