// src/components/ui/button.js

import React, { forwardRef, useRef, useImperativeHandle, useEffect, useCallback, useState } from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';
import { cn } from '../../lib/utils';

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10", // Perfect for icon-only buttons
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

const Button = forwardRef(({
  className,
  variant,
  size,
  asChild = false,
  loading = false,
  loadingText,
  iconLeft,
  iconRight,
  fullWidth = false,
  disabled,
  children,
  ...props
}, ref) => {
  const [isMounted, setIsMounted] = useState(false);
  const buttonRef = useRef(null);

  useImperativeHandle(ref, () => buttonRef.current);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (isMounted && buttonRef.current) {
      buttonRef.current.setAttribute('aria-busy', loading ? 'true' : 'false');
      if (loading && loadingText) {
        buttonRef.current.setAttribute('aria-label', loadingText);
      }
    }
  }, [loading, loadingText, isMounted]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      buttonRef.current?.click();
    }
  }, []);

  const isDisabled = disabled || loading;

  const buttonContent = (
    <>
      {loading && (
        <span className="mr-2 inline-block animate-spin">⟳</span>
      )}
      {iconLeft && !loading && (
        <span className="mr-2 inline-flex">{iconLeft}</span>
      )}
      {loading && loadingText ? loadingText : children}
      {iconRight && !loading && (
        <span className="ml-2 inline-flex">{iconRight}</span>
      )}
    </>
  );

  const Comp = asChild ? Slot : "button";

  return (
    <Comp
      ref={buttonRef}
      className={cn(
        buttonVariants({ variant, size }),
        fullWidth && "w-full",
        className
      )}
      disabled={isDisabled}
      onKeyDown={handleKeyDown}
      data-state={loading ? "loading" : undefined}
      {...props}
    >
      {buttonContent}
    </Comp>
  );
});

Button.displayName = "Button";

export { Button, buttonVariants };
