import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import { ChevronDown, ChevronUp } from 'lucide-react';
import './StatisticsFeedback.css';

const FeedbackPeriodSelector = ({ selectedPeriod, onPeriodChange }) => {
  return (
    <div className="feedback-period-selector">
      <div className="flex justify-center gap-4 mb-8">
        <button
          className={`px-4 py-2 rounded-lg ${
            selectedPeriod === 'mid' 
              ? 'bg-blue-600 text-white' 
              : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => onPeriodChange('mid')}
        >
          Mid-Semester Feedback
        </button>
        <button
          className={`px-4 py-2 rounded-lg ${
            selectedPeriod === 'end' 
              ? 'bg-blue-600 text-white' 
              : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => onPeriodChange('end')}
        >
          End of Semester Feedback
        </button>
      </div>
    </div>
  );
};

const Section = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = React.useState(true);
  return (
    <div className="feedback-section">
      <button 
        className="section-header" 
        onClick={() => setIsExpanded(!isExpanded)}
        type="button"
        aria-expanded={isExpanded}
        aria-controls={`section-content-${title}`}
      >
        <h3 id={`section-header-${title}`}>{title}</h3>
        {isExpanded ? <ChevronUp size={20} aria-label="Collapse section" /> : <ChevronDown size={20} aria-label="Expand section" />}
      </button>
      {isExpanded && (
        <div 
          id={`section-content-${title}`} 
          className="section-content"
          role="region"
          aria-labelledby={`section-header-${title}`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

const CourseSection = ({ title, questions, selectedPeriod, improvements }) => {
  const calculateStats = (responses) => {
    const ratings = [...responses].map(Number);
    const counts = Array(5).fill(0);
    ratings.forEach(r => {
      if (r >= 1 && r <= 5) counts[r-1]++;
    });
    
    const mean = ratings.reduce((a, b) => a + b, 0) / ratings.length;
    const sortedRatings = [...ratings].sort((a, b) => a - b);
    const median = sortedRatings[Math.floor(ratings.length / 2)];
    
    return {
      rating1: counts[0],
      rating2: counts[1],
      rating3: counts[2],
      rating4: counts[3],
      rating5: counts[4],
      mean: mean.toFixed(2),
      median,
      total: ratings.length
    };
  };

  return (
    <div className="course-section">
      <h1 className="feedback-title">{title}</h1>
      {questions.length > 0 && (
        <Section title="Rating Distribution">
          <div className="charts-container">
            {questions.map((q) => {
              const stats = calculateStats(q.responses);
              if (!stats) return null;

              const chartData = [{
                '1 Star': stats.rating1,
                '2 Stars': stats.rating2,
                '3 Stars': stats.rating3,
                '4 Stars': stats.rating4,
                '5 Stars': stats.rating5
              }];

              return (
                <div key={q.id} className="chart-wrapper">
                  <h4 className="question-text">{q.text}</h4>
                  <div className="stats-row">
                    <div className="stat-box">
                      <span className="stat-label">Responses</span>
                      <span className="stat-value">{stats.total}</span>
                    </div>
                    <div className="stat-box">
                      <span className="stat-label">Median</span>
                      <span className="stat-value">{stats.median}</span>
                    </div>
                    <div className="stat-box">
                      <span className="stat-label">Mean</span>
                      <span className="stat-value">{stats.mean}</span>
                    </div>
                  </div>
                  <div className="chart">
                    <ResponsiveContainer width="100%" height={250}>
                      <BarChart 
                        data={chartData} 
                        margin={{ top: 20, right: 30, left: 40, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis hide />
                        <YAxis
                          tickLine={false}
                          axisLine={true}
                          label={{ 
                            value: 'Students',
                            angle: -90,
                            position: 'insideLeft',
                            offset: -5
                          }}
                        />
                        <Tooltip 
                          formatter={(value) => [`${value} students`, 'Responses']}
                        />
                        <Bar dataKey="1 Star" fill="#ef4444">
                          <LabelList dataKey="1 Star" position="top" />
                        </Bar>
                        <Bar dataKey="2 Stars" fill="#f97316">
                          <LabelList dataKey="2 Stars" position="top" />
                        </Bar>
                        <Bar dataKey="3 Stars" fill="#eab308">
                          <LabelList dataKey="3 Stars" position="top" />
                        </Bar>
                        <Bar dataKey="4 Stars" fill="#84cc16">
                          <LabelList dataKey="4 Stars" position="top" />
                        </Bar>
                        <Bar dataKey="5 Stars" fill="#22c55e">
                          <LabelList dataKey="5 Stars" position="top" />
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                    <div className="chart-legend">
                      {[
                        { label: '1 Star', color: '#ef4444' },
                        { label: '2 Stars', color: '#f97316' },
                        { label: '3 Stars', color: '#eab308' },
                        { label: '4 Stars', color: '#84cc16' },
                        { label: '5 Stars', color: '#22c55e' }
                      ].map(({ label, color }) => (
                        <div key={label} className="legend-item">
                          <span 
                            className="legend-color" 
                            style={{ backgroundColor: color }}
                            aria-hidden="true"
                          />
                          {label}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          </Section>
      )}
      {improvements && (
        <Section title="Key Areas for Improvement">
          <div className="improvement-areas">
            {Object.entries(improvements).map(([key, section]) => (
              <div key={key} className="improvement-section">
                <h4>{section.title}</h4>
                <ul>
                  {section.items.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </Section>
      )}
    </div>
  );
};

const StatisticsFeedback = () => {
  const [selectedPeriod, setSelectedPeriod] = useState('mid');

  
  const midSemesterData = {
    
    statisticsCourseQuestions: [
      {
        id: 'stat_q1',
        text: 'The course provided a solid foundation in statistical theory and methods',
        responses: '554443443334435253255344'
      },
      {
        id: 'stat_q2',
        text: 'The practical exercises (labs, projects) effectively reinforced theoretical concepts',
        responses: '423253234343434233255455'
      },
      {
        id: 'stat_q3',
        text: 'The course materials were well-organized and easy to follow',
        responses: '124143223234235245244452'
      },
      {
        id: 'stat_q4',
        text: "The instructor's teaching style was effective (clear, engaging, supportive)",
        responses: '443121214232334432345353'
      },
      {
        id: 'stat_q5',
        text: 'The assignments were appropriate in difficulty and relevance',
        responses: '514352432353454233343453'
      },
      {
        id: 'stat_q6',
        text: 'The pace of the course was appropriate for the level of content being taught',
        responses: '542251411213214242244452'
      }
    ],
    
    aiCourseQuestions: [
      {
        id: 'ai_q1_mid',
        text: 'The AI and machine learning concepts covered so far are presented clearly and accessibly',
        responses: '432224312323414233243233'
      },
      {
        id: 'ai_q2_mid',
        text: 'The current assignments are helping build practical skills in applying AI techniques',
        responses: '153421412344315215245244'
      },
      {
        id: 'ai_q3_mid',
        text: 'The course materials were well-organized and easy to follow',
        responses: '333233431234214233353124'
      },
      {
        id: 'ai_q4_mid',
        text: "The instructor's teaching style is effective (clear, engaging, supportive)",
        responses: '223122311223225233255134'
      },
      {
        id: 'ai_q5_mid',
        text: 'The pace and difficulty of assignments are appropriate for learning',
        responses: '354432412254315313255333'
      }
    ],
    dataSocietyQuestions: [
      {
        id: 'ds_q1_mid',
        text: 'The course is effectively introducing ethical implications of data science/AI',
        responses: '544545534344344453345543'
      },
      {
        id: 'ds_q2_mid',
        text: "The initial topics provide good frameworks for thinking critically about data's role in society",
        responses: '533455533445345453345554'
      },
      {
        id: 'ds_q3_mid',
        text: 'The course materials are well-organized and easy to follow',
        responses: '534354432344335453353554'
      },
      {
        id: 'ds_q4_mid',
        text: "The instructor's teaching style is effective (clear, engaging, supportive)",
        responses: '554455442344335553344554'
      },
      {
        id: 'ds_q5_mid',
        text: 'The initial pace and level of content are appropriate',
        responses: '533242552243334423344553'
      }
    ],
    programQuestions: [
      {
        id: 'prog_q1_mid',
        text: 'The program structure effectively combines technical skills with social considerations',
        responses: '434452432344344255145434'
      },
      {
        id: 'prog_q2_mid',
        text: 'The courses so far are building a comprehensive understanding of data science',
        responses: '444454432344344255345333'
      }
    ],
    statisticsCourseImprovements: {
      courseStructure: {
        title: "Course Structure & Organization",
        items: [
          "Establish a single source of truth in Canvas modules for course materials, announcements, and updates",
          "Provide comprehensive assignment guidelines and grading rubrics at course start",
          "Implement transparent quiz schedule with clear passing requirements and point distribution",
          "Ensure stronger alignment between lectures, exercises, and exams with adjusted content volume"
        ]
      },
      teachingSupport: {
        title: "Teaching & Learning Support",
        items: [
          "Incorporate more real-world examples and practical applications throughout the course",
          "Provide additional practice exercises that align with lecture content",
          "Offer structured exam preparation with practice questions and review sessions",
          "Implement structured approach to academic paper reading and seminar participation"
        ]
      },
      contentDelivery: {
        title: "Content Delivery",
        items: [
          "Strike better balance between theoretical foundations and practical applications",
          "Develop step-by-step guides and worked examples for challenging topics",
          "Create clearer connections between different course components and concepts",
          "Implement gradual introduction of complex concepts with foundational understanding"
        ]
      },
      assessment: {
        title: "Assessment & Feedback",
        items: [
          "Establish consistent and timely communication about assessment deadlines and requirements",
          "Implement thorough quality control for quiz questions and instructions",
          "Provide comprehensive practice materials aligned with exam format",
          "Implement regular feedback opportunities throughout the course duration"
        ]
      }
    },
    // Mid-semester AI improvements
    aiCourseImprovements: {
      courseStructure: {
        title: "Course Structure & Organization",
        items: [
          "Implement a consistent schedule for releasing lecture materials at least 48 hours before each session",
          "Create a structured document repository in Canvas with clear organization of materials",
          "Establish fixed deadlines at the start of the course",
          "Develop comprehensive project guidelines with clear deliverables"
        ]
      },
      teachingSupport: {
        title: "Teaching & Learning Support",
        items: [
          "Structure seminar sessions with clear agendas and discussion topics",
          "Ensure thorough instructor preparation for each lecture",
          "Institute regular office hours for assignment clarification",
          "Create collaborative opportunities between AI and statistics courses"
        ]
      },
      contentDelivery: {
        title: "Content Delivery",
        items: [
          "Adjust lecture pacing to include dedicated time for questions",
          "Implement feedback loops during lectures using quick polls",
          "Record and provide access to key concept explanations",
          "Develop supplementary resources and practice materials"
        ]
      },
      assessment: {
        title: "Assessment & Feedback",
        items: [
          "Institute systematic peer review process for assignments",
          "Implement regular progress check-ins during projects",
          "Create comprehensive exam preparation materials",
          "Establish transparent grading timeline with regular updates"
        ]
      }
    },
    overallProgramImprovements: {
      courseStructure: {
        title: "Program Structure & Organization",
        items: [
          "Implement centralized Canvas organization with dedicated assignment modules separate from people tab",
          "Establish fixed deadlines across all courses with minimum 2-week notice for any changes",
          "Create structured cross-course planning to prevent assignment overlap between AI and Data & Society courses",
          "Develop comprehensive study guides with clear expectations, deadlines, and resource links available from course start"
        ]
      },
      teachingSupport: {
        title: "Communication & Accessibility",
        items: [
          "Establish Discord presence for all instructors with defined response time expectations",
          "Implement 48-hour maximum response time policy for email inquiries",
          "Create dedicated office hours across courses for assignment clarification",
          "Organize regular informal fika events to strengthen program community and facilitate discussions"
        ]
      },
      contentDelivery: {
        title: "Learning Experience",
        items: [
          "Balance technical depth with adequate time for concept digestion and practical application",
          "Provide explicit learning outcomes mapping to industry requirements and competencies",
          "Create clear progression paths showing how courses build upon each other",
          "Establish comprehensive exam preparation guidelines with past papers and review materials"
        ]
      },
      assessment: {
        title: "Quality Assurance",
        items: [
          "Release complete project requirements and rubrics at course start",
          "Coordinate assignment schedules across courses to ensure manageable workload",
          "Implement systematic feedback collection and response mechanism",
          "Create transparent assessment criteria aligned with industry standards and expectations"
        ]
      }
    }
  };

  const endSemesterData = {
    // AI Course Questions with response data from CSV
    aiCourseQuestions: [
      {
        id: 'ai_q1_end',
        text: 'The AI and machine learning concepts covered so far are presented clearly and accessibly',
        responses: '3243444324343553444'  // From CSV column 2
      },
      {
        id: 'ai_q2_end',
        text: 'The current assignments are helping build practical skills in applying AI techniques',
        responses: '2454331224445353344'  // From CSV column 3
      },   
      {
        id: 'ai_q3_end',
        text: 'The course materials were well-organized and easy to follow',
        responses: '2333435313443453243'  // From CSV column 4
      },      
      {
        id: 'ai_q4_end',
        text: "The instructor's teaching style is effective (clear, engaging, supportive)",
        responses: '2341231113442353223'  // From CSV column 5
      },  
      {
        id: 'ai_q5_end',
        text: 'The pace and difficulty of assignments are appropriate for learning',
        responses: '2344442413445452443'  // From CSV column 6
      }
    ],
    // Data & Society Course Questions with response data from CSV
    dataSocietyCourseQuestions: [
      {
        id: 'ds_q1_end',
        text: 'The course is effectively introducing ethical implications of data science/AI',
        responses: '3354534544345553344'  // From CSV column 8
      },
      {
        id: 'ds_q2_end',
        text: "The initial topics provide good frameworks for thinking critically about data's role in society",
        responses: '3353535544345553244'  // From CSV column 9
      },
      
      {
        id: 'ds_q3_end',
        text: 'The course materials are well-organized and easy to follow',
        responses: '3323435353444553244'  // From CSV column 10
      },   
      {
        id: 'ds_q4_end',
        text: "The instructor's teaching style is effective (clear, engaging, supportive)",
        responses: '3332533443345453234'  // From CSV column 11
      },
      {
        id: 'ds_q5_end',
        text: 'The initial pace and level of content are appropriate',
        responses: '3344435452444552244'  // From CSV column 12
      }    
    ], 
    // Program Questions with response data from CSV
    programQuestions: [
      {
        id: 'prog_q1_end',
        text: 'The program structure effectively combines technical skills with social considerations',
        responses: '2344343434434551244'  // From CSV column 14
      },    
      {
        id: 'prog_q2_end',
        text: 'The courses so far are building a comprehensive understanding of data science',
        responses: '2344444433445553244'  // From CSV column 15
      }
    ],
  
    // End-semester improvement areas for AI course (reorganized from MD file)
    endSemesterAIImprovements: {
      courseStructure: {
        title: "Course Structure & Organization",
        items: [
          "Implement 48-hour advance material release policy and establish stable deadlines with a 2-week notice requirement for any changes",
          "Develop comprehensive project guidelines with clear evaluation criteria, technical requirements, and milestone expectations",
          "Create a structured Canvas repository with clear organization of lectures, assignments, and announcements",
          "Establish consistent schedules for all course components and deadlines"
        ]
      },
      teachingSupport: {
        title: "Teaching & Learning Support",
        items: [
          "Set up regular office hours with guaranteed email response times",
          "Enhance seminar effectiveness with instructor-guided discussions and structured breakout sessions",
          "Increase practical content with real-world examples and step-by-step algorithm implementations",
          "Implement regular progress reviews and feedback sessions"
        ]
      },
      contentDelivery: {
        title: "Content Delivery",
        items: [
          "Balance theoretical foundations with increased machine learning applications",
          "Use quick polls and brief exercises during lectures to gauge understanding",
          "Provide recorded explanations and worked examples for complex topics",
          "Develop a comprehensive resource repository for review materials"
        ]
      },
      assessment: {
        title: "Assessment & Feedback",
        items: [
          "Create structured exam preparation package with practice papers and sample solutions",
          "Schedule periodic project progress reviews with instructor feedback",
          "Establish clear grading timeline with detailed feedback mechanisms",
          "Implement systematic peer review process with clear rubrics"
        ]
      }
    },
    endSemesterDataAndSocietyImprovements: {
      courseStructure: {
        title: "Course Structure & Organization",
        items: [
          "Redistribute assignments and evaluations across semester; create balanced schedule with buffer periods between major deadlines",
          "Provide structured guidance for project components; create detailed selection criteria and examples for both subjects and datasets",
          "Establish unified evaluation standards; create and distribute comprehensive grading rubrics to all teaching staff",
          "Define clear progression path between assignments; document how each assignment builds upon previous work"
        ]
      },
      teachingSupport: {
        title: "Teaching & Learning Support",
        items: [
          "Streamline instruction documentation with clear, concise step-by-step format",
          "Hold regular alignment meetings for teaching staff and create shared documentation of expectations and requirements",
          "Rebalance teaching approach by creating structured examples of correct methodologies while noting common pitfalls"
        ]
      },
      contentDelivery: {
        title: "Content Delivery",
        items: [
          "Define clear content boundaries and create focused learning objectives for each course component",
          "Provide scaffolded freedom by offering structured options while maintaining creative choice",
          "Establish single source of truth by centralizing course communications through Canvas"
        ]
      },
      assessment: {
        title: "Assessment & Feedback",
        items: [
          "Optimize workload by reviewing and revising assignment structure based on learning objectives, reducing number of assignments or adjusting scope",
          "Standardize grading process with detailed rubrics and examples for each grade level",
          "Create clear learning pathway by documenting how each assignment contributes to course objectives"
        ]
      }
    },
  
    // Additional Comments section 
    lastComment: []
  };

  return (
    <div className="statistics-feedback-wrapper">
      <FeedbackPeriodSelector 
        selectedPeriod={selectedPeriod}
        onPeriodChange={setSelectedPeriod}
      />
  
      <div className="statistics-feedback-container">
        {selectedPeriod === 'mid' ? (
          // Mid-semester feedback sections (Statistics Course + AI Course mid-semester data)
          <>
            <CourseSection 
              title="Statistics Course" 
              questions={midSemesterData.statisticsCourseQuestions}
              selectedPeriod={selectedPeriod}
              improvements={midSemesterData.statisticsCourseImprovements}
            />
            <CourseSection 
              title="AI for Data Science Course" 
              questions={midSemesterData.aiCourseQuestions}
              selectedPeriod={selectedPeriod}
              improvements={midSemesterData.aiCourseImprovements}
            />
            <CourseSection 
              title="Overall Program Evaluation" 
              questions={midSemesterData.programQuestions}
              selectedPeriod={selectedPeriod}
              improvements={midSemesterData.overallProgramImprovements}       
            />
          </>
        ) : (
          // End-semester feedback sections (Data & Society Course + AI Course end-semester data)
          <>
            <CourseSection 
              title="AI for Data Science Course" 
              questions={endSemesterData.aiCourseQuestions}
              selectedPeriod={selectedPeriod}
              improvements={endSemesterData.endSemesterAIImprovements}
            />
            <CourseSection 
              title="Data and Society Course" 
              questions={endSemesterData.dataSocietyCourseQuestions}
              selectedPeriod={selectedPeriod}
              improvements={endSemesterData.endSemesterDataAndSocietyImprovements}              
            />
            <CourseSection 
              title="Overall Program Evaluation" 
              questions={endSemesterData.programQuestions}
              selectedPeriod={selectedPeriod}
            />
            <CourseSection 
              title="Additional Comments" 
              questions={endSemesterData.lastComment}
              selectedPeriod={selectedPeriod}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default StatisticsFeedback;