// src/components/magicui/confetti.tsx

"use client";

import React, {
  createContext,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  type ReactNode
} from "react";
import confetti, { 
  CreateTypes,
  GlobalOptions,
  Options,
  Shape
} from "canvas-confetti";

interface ConfettiApi {
  fire: (options?: Options) => Promise<void>;
}

interface ConfettiProps extends React.ComponentPropsWithRef<"canvas"> {
  options?: Options;
  globalOptions?: GlobalOptions;
  manualstart?: boolean;
  children?: ReactNode;
}

const ConfettiContext = createContext<ConfettiApi | null>(null);

export type ConfettiRef = ConfettiApi;

const Confetti = forwardRef<ConfettiRef, ConfettiProps>((props, ref) => {
  const {
    options = {},
    globalOptions = { resize: true, useWorker: false },
    manualstart = false,
    children,
    ...rest
  } = props;

  const instanceRef = useRef<CreateTypes | null>(null);
  const errorRef = useRef<Error | null>(null);
  const mountedRef = useRef<boolean>(true);

  const canvasRef = useCallback(
    (node: HTMLCanvasElement | null) => {
      try {
        if (node !== null && !instanceRef.current) {
          instanceRef.current = confetti.create(node, {
            ...globalOptions,
            resize: true,
          });
        } else if (!node && instanceRef.current) {
          instanceRef.current.reset();
          instanceRef.current = null;
        }
      } catch (error) {
        errorRef.current = error instanceof Error ? error : new Error('Canvas initialization failed');
        console.error('Confetti initialization error:', error);
      }
    },
    [globalOptions]
  );

  const fire = useCallback(
    async (opts: Options = {}) => {
      if (!mountedRef.current) {
        return;
      }

      if (errorRef.current) {
        throw errorRef.current;
      }

      if (!instanceRef.current) {
        throw new Error('Confetti instance not initialized');
      }

      try {
        await instanceRef.current({
          ...options,
          ...opts,
        });
      } catch (error) {
        const firingError = error instanceof Error ? error : new Error('Confetti firing failed');
        console.error('Confetti firing error:', firingError);
        throw firingError;
      }
    },
    [options]
  );

  useImperativeHandle(
    ref,
    () => ({
      fire
    }),
    [fire]
  );

  useEffect(() => {
    if (!manualstart && mountedRef.current) {
      fire().catch((error) => {
        if (mountedRef.current) {
          console.error('Auto-start confetti error:', error);
        }
      });
    }

    return () => {
      mountedRef.current = false;
      if (instanceRef.current) {
        try {
          instanceRef.current.reset();
          instanceRef.current = null;
        } catch (error) {
          console.error('Cleanup error:', error);
        }
      }
    };
  }, [manualstart, fire]);

  if (errorRef.current) {
    console.error('Confetti component error:', errorRef.current);
    return null;
  }

  return (
    <ConfettiContext.Provider value={{ fire }}>
      <canvas 
        ref={canvasRef}
        style={{ 
          pointerEvents: 'none',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 9999, /* Ensure it's on top */
          ...rest.style
        }}
        {...rest}
      />
      {children}
    </ConfettiContext.Provider>
  );
});

Confetti.displayName = 'Confetti';

export default Confetti;
