// src/components/StatBar.js
"use client";

import React from "react";
import PropTypes from "prop-types";
import "../styles/StatBar.css";

/**
 * StatBar Component
 * 
 * Represents a statistical bar visualization with different modes:
 * - 'answering': Shows passed and failed counts.
 * - 'results': Shows correct, incorrect, and not answered counts with percentages.
 * 
 * @param {string} type - Display mode ('answering' or 'results')
 * @param {number} total - Total number of flashcards
 * @param {number} correct - Number of correct answers (for 'results' and 'answering')
 * @param {number} incorrect - Number of incorrect answers (for 'results' and 'answering')
 * @param {number} notAnswered - Number of unanswered flashcards (only for 'results')
 */
const StatBar = ({
  type,
  total,
  correct = 0,
  incorrect = 0,
  notAnswered = 0
}) => {
  /**
   * Calculates percentages for each segment based on total.
   * @param {Array} segments - Array of segment objects with 'value' and 'className'.
   * @returns {Array} Array of segment objects with 'percentage' added.
   */
  const calculatePercentages = (segments) => {
    return segments.map((seg) => ({
      ...seg,
      percentage: total > 0 ? ((seg.value / total) * 100).toFixed(1) : "0.0"
    }));
  };

  if (type === "answering") {
    const segments = calculatePercentages([
      { 
        label: "Incorrect", 
        value: incorrect, 
        className: "incorrect" 
      },
      { 
        label: "Correct", 
        value: correct, 
        className: "correct" 
      }
    ]);

    // Debugging logs
    console.log("StatBar Props (Answering):", { type, total, correct, incorrect, notAnswered });
    console.log("Calculated Segments (Answering):", segments);

    return (
      <div className="statbar-wrapper">
        <div className="statbar-info">
          <span>{`${correct + incorrect} Answered / ${total} Total`}</span>
        </div>
        <div className="statbar-container answering">
          {segments.map((seg, idx) => (
            <div
              key={idx}
              className={`statbar-segment ${seg.className}`}
              style={{ width: `${seg.percentage}%` }}
              title={`${seg.value} ${seg.label}`}
            />
          ))}
        </div>
        <div className="statbar-counts">
          {segments.map((seg, idx) => (
            <span 
              key={idx} 
              className={`statbar-count ${seg.className}`}
            >
              {`${seg.value} ${seg.label}`}
            </span>
          ))}
        </div>
      </div>
    );
  }

  if (type === "results") {
    const segments = calculatePercentages([
      { 
        label: "Correct", 
        value: correct, 
        className: "correct" 
      },
      { 
        label: "Incorrect", 
        value: incorrect, 
        className: "incorrect" 
      },
      { 
        label: "Not Answered", 
        value: notAnswered, 
        className: "not-answered" 
      }
    ]);

    // Debugging logs
    console.log("StatBar Props (Results):", { type, total, correct, incorrect, notAnswered });
    console.log("Calculated Segments (Results):", segments);

    return (
      <div className="statbar-wrapper">
        <div className="statbar-info">
          <span>{`${correct + incorrect} Answered / ${total} Total`}</span>
        </div>
        <div className="statbar-container results">
          {segments.map((seg, idx) => (
            <div
              key={idx}
              className={`statbar-segment ${seg.className}`}
              style={{ width: `${seg.percentage}%` }}
              title={`${seg.value} ${seg.label}`}
            />
          ))}
        </div>
        <div className="statbar-counts">
          {segments.map((seg, idx) => (
            <span 
              key={idx} 
              className={`statbar-count ${seg.className}`}
            >
              {`${seg.value} ${seg.label} (${seg.percentage}%)`}
            </span>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

StatBar.propTypes = {
  type: PropTypes.oneOf(["answering", "results"]).isRequired,
  total: PropTypes.number.isRequired,
  correct: PropTypes.number,
  incorrect: PropTypes.number,
  notAnswered: PropTypes.number,
};

StatBar.defaultProps = {
  correct: 0,
  incorrect: 0,
  notAnswered: 0,
};

export default StatBar;
