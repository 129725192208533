// src/components/ui/alert.js
import * as React from "react";
import { cva } from "class-variance-authority";
import { cn } from "../../lib/utils";

const alertVariants = cva(
  "relative w-full rounded-lg border p-4 [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground",
  {
    variants: {
      variant: {
        default: "bg-background text-foreground",
        destructive: "border-destructive/50 text-destructive dark:border-destructive [&>svg]:text-destructive",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

const Alert = React.forwardRef(({ 
  className, 
  variant, 
  children,
  onClose,
  ...props 
}, ref) => {
  const alertRef = React.useRef(null);
  
  React.useImperativeHandle(ref, () => alertRef.current);

  React.useEffect(() => {
    if (alertRef.current) {
      // Ensure alert is announced to screen readers
      alertRef.current.setAttribute('aria-live', 'polite');
    }
  }, []);

  const handleKeyDown = React.useCallback((event) => {
    if (event.key === 'Escape' && onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <div
      ref={alertRef}
      role="alert"
      className={cn(alertVariants({ variant }), className)}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      {...props}
    >
      {children}
    </div>
  );
});

const AlertTitle = React.forwardRef(({ 
  className, 
  children,
  ...props 
}, ref) => {
  const titleRef = React.useRef(null);
  
  React.useImperativeHandle(ref, () => titleRef.current);

  return (
    <h5
      ref={titleRef}
      className={cn("mb-1 font-medium leading-none tracking-tight", className)}
      {...props}
    >
      {children}
    </h5>
  );
});

const AlertDescription = React.forwardRef(({ 
  className,
  children, 
  ...props 
}, ref) => {
  const descriptionRef = React.useRef(null);
  
  React.useImperativeHandle(ref, () => descriptionRef.current);

  return (
    <div
      ref={descriptionRef}
      className={cn("text-sm [&_p]:leading-relaxed", className)}
      {...props}
    >
      {children}
    </div>
  );
});

Alert.displayName = "Alert";
AlertTitle.displayName = "AlertTitle";
AlertDescription.displayName = "AlertDescription";

export { Alert, AlertTitle, AlertDescription, alertVariants };