// src/components/ui/input.js
import React, { forwardRef, useRef, useImperativeHandle, useEffect } from 'react';
import { cn } from '../../lib/utils';

const Input = forwardRef(({
  className,
  error,
  helperText,
  type,
  ...props
}, ref) => {
  const inputRef = useRef(null);
  
  useImperativeHandle(ref, () => inputRef.current);

  useEffect(() => {
    const supportedTypes = [
      "text", "password", "email", "number", "search", "tel", "url", "color",
      "date", "datetime-local", "month", "time", "week", "file"
    ];
    if (type && !supportedTypes.includes(type)) {
      console.warn(`Input type '${type}' might not be supported in all browsers`);
    }
  }, [type]);

  const inputClasses = cn(
    "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2",
    "text-sm ring-offset-background file:border-0 file:bg-transparent",
    "file:text-sm file:font-medium placeholder:text-muted-foreground",
    "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring",
    "focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
    error && "border-red-500 focus-visible:ring-red-500",
    className
  );

  return (
    <div className="relative">
      <input
        type={type}
        className={inputClasses}
        ref={inputRef}
        {...props}
      />
      {helperText && (
        <p className={cn(
          "mt-1 text-xs",
          error ? "text-red-500" : "text-muted-foreground"
        )}>
          {helperText}
        </p>
      )}
    </div>
  );
});

Input.displayName = "Input";

export { Input };