// ui/slider.js
import * as React from "react";
import * as SliderPrimitive from "@radix-ui/react-slider";
import { cn } from "../../lib/utils"; // Ensure this utility is correctly implemented

// Custom Error Class for Slider Validation
class SliderError extends Error {
    constructor(message) {
        super(message);
        this.name = 'SliderError';
    }
}

// Validation Function for Slider Props
const validateSliderProps = (props) => {
    const { value, defaultValue, min, max, step } = props;
    
    if (min >= max) {
        throw new SliderError('Min value must be less than max value');
    }

    if (step <= 0) {
        throw new SliderError('Step value must be positive');
    }

    const checkValues = (vals, type) => {
        if (!Array.isArray(vals)) {
            throw new SliderError(`${type} must be an array`);
        }
        vals.forEach(v => {
            if (v < min) {
                throw new SliderError(`Value ${v} is less than min ${min}`);
            }
            if (v > max) {
                throw new SliderError(`Value ${v} is greater than max ${max}`);
            }
        });
    };

    if (value !== undefined) {
        checkValues(value, 'Value');
    }

    if (defaultValue !== undefined) {
        checkValues(defaultValue, 'DefaultValue');
    }
};

// Slider Component
const Slider = React.forwardRef(({
    className,
    value,
    defaultValue,
    min = 0,
    max = 100,
    step = 1,
    orientation = "horizontal",
    disabled = false,
    onValueChange,
    onValueCommit,
    inverted = false,
    showMarks = false, // New prop to show marks
    marks = [], // Array of marks { value: number, label: string }
    ...props
}, ref) => {
    const [localValue, setLocalValue] = React.useState(defaultValue || [min]);
    const sliderRef = React.useRef(null);
    
    // Expose imperative methods
    React.useImperativeHandle(ref, () => ({
        ...sliderRef.current,
        getValue: () => localValue,
        setValue: (newValue) => {
            if (Array.isArray(newValue)) {
                setLocalValue(newValue);
            }
        }
    }));

    // Validate props on mount and when dependencies change
    React.useEffect(() => {
        try {
            validateSliderProps({ value, defaultValue, min, max, step });
        } catch (error) {
            if (error instanceof SliderError) {
                console.error('Slider validation error:', error.message);
                setLocalValue([min]);
            } else {
                console.error('Unknown Slider validation error:', error);
            }
        }
    }, [value, defaultValue, min, max, step]);

    // Handle value changes
    const handleValueChange = React.useCallback((newValue) => {
        setLocalValue(newValue);
        onValueChange?.(newValue);
    }, [onValueChange]);

    // Handle value commits (e.g., when user finishes dragging)
    const handleValueCommit = React.useCallback((newValue) => {
        onValueCommit?.(newValue);
    }, [onValueCommit]);

    // Handle keyboard interactions
    const handleKeyDown = React.useCallback((event) => {
        if (disabled) return;

        const increment = event.shiftKey ? step * 10 : step;
        const currentValue = localValue[0];
        let newValue;

        switch (event.key) {
            case 'ArrowRight':
            case 'ArrowUp':
                newValue = Math.min(currentValue + increment, max);
                handleValueChange([newValue]);
                break;
            case 'ArrowLeft':
            case 'ArrowDown':
                newValue = Math.max(currentValue - increment, min);
                handleValueChange([newValue]);
                break;
            case 'Home':
                handleValueChange([min]);
                break;
            case 'End':
                handleValueChange([max]);
                break;
            default:
                return;
        }

        event.preventDefault();
    }, [disabled, step, localValue, max, min, handleValueChange]);

    return (
        <div className={cn("relative flex flex-col items-center", className)}>
            <SliderPrimitive.Root
                ref={sliderRef}
                className={cn(
                    "relative flex touch-none select-none items-center",
                    orientation === "horizontal" ? "w-full h-5" : "flex-col h-full w-5",
                    inverted ? "flex-row-reverse" : "",
                    disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer",
                    props.className
                )}
                value={value || localValue}
                defaultValue={defaultValue}
                min={min}
                max={max}
                step={step}
                orientation={orientation}
                disabled={disabled}
                inverted={inverted}
                onValueChange={handleValueChange}
                onValueCommit={handleValueCommit}
                onKeyDown={handleKeyDown}
                {...props}
            >
                <SliderPrimitive.Track
                    className={cn(
                        "relative grow rounded-full bg-secondary",
                        orientation === "horizontal" ? "h-1.5 w-full" : "h-full w-1.5"
                    )}
                >
                    <SliderPrimitive.Range 
                        className="absolute bg-primary rounded-full"
                        style={{
                            [orientation === "horizontal" ? "left" : "bottom"]: 0,
                            [orientation === "horizontal" ? "right" : "top"]: "auto",
                            [orientation === "horizontal" ? "width" : "height"]: "100%"
                        }}
                    />
                </SliderPrimitive.Track>
                <SliderPrimitive.Thumb
                    className={cn(
                        "block h-4 w-4 rounded-full border border-primary/50",
                        "bg-background shadow transition-colors focus-visible:outline-none",
                        "focus-visible:ring-1 focus-visible:ring-ring",
                        "disabled:pointer-events-none",
                        "disabled:opacity-50"
                    )}
                    aria-label="Slider Thumb"
                    aria-valuemin={min}
                    aria-valuemax={max}
                    aria-valuenow={value?.[0] || localValue[0]}
                    data-orientation={orientation}
                />
            </SliderPrimitive.Root>

            {/* Marks and Labels */}
            {showMarks && marks.length > 0 && (
                <div className={cn(
                    orientation === "horizontal" ? "flex justify-between w-full mt-2" : "flex flex-col justify-between h-full ml-2"
                )}>
                    {marks.map((mark, index) => (
                        <div key={index} className="flex flex-col items-center">
                            <div className="w-2 h-2 bg-gray-400 rounded-full mb-1"></div>
                            <span className="text-xs text-white">{mark.label}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
});

Slider.displayName = "Slider";

export { Slider };
