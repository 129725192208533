// src/functions/SingleCardPage.js
"use client";

import React, { useState, useEffect } from "react";
import SingleCard from "../components/SingleCard";
import StatBar from "../components/StatBar";
import { shuffleArray, shuffleAnswersAndUpdateIndex } from "../utils/shuffle";
import "../styles/CategoryButtons.css";

// Define the categories and their corresponding JSON files
const CATEGORIES = [
  {
    name: "Lectures",
    files: Array.from({ length: 14 }, (_, i) => `lecture${i + 1}.json`),
  },
  {
    name: "Prev Exam Variations",
    files: ["old_exam.json"],
  },
  {
    name: "Isak's Deck",
    files: ["isak1.json"],
  },
  {
    name: "Advanced",
    files: ["advanced.json"],
  },
];

const SingleCardPage = () => {
  const [flashcards, setFlashcards] = useState([]);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [passedCount, setPassedCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // State to manage active categories
  const [activeCategories, setActiveCategories] = useState({
    Lectures: true,
    "Prev Exam Variations": true,
    "Isak's Deck": true,
    Advanced: true,
  });

  /**
   * Handles toggling of categories.
   * Ensures that at least one category remains active.
   * @param {string} categoryName - The name of the category to toggle.
   */
  const toggleCategory = (categoryName) => {
    const activeCount = Object.values(activeCategories).filter(Boolean).length;

    // If only one category is active and the user tries to deactivate it, prevent it
    if (activeCategories[categoryName] && activeCount === 1) {
      alert("At least one category must be active to continue.");
      return;
    }

    setActiveCategories((prev) => ({
      ...prev,
      [categoryName]: !prev[categoryName],
    }));
  };

  /**
   * Renders category toggle buttons.
   * @returns {JSX.Element} The category toggle buttons.
   */
  const renderToggleButtons = () => {
    const activeCount = Object.values(activeCategories).filter(Boolean).length;

    return (
      <div className="category-button-container mb-4">
        {CATEGORIES.map((category) => {
          const isOnlyActive =
            activeCategories[category.name] && activeCount === 1;

          return (
            <button
              key={category.name}
              onClick={() => toggleCategory(category.name)}
              disabled={isOnlyActive} // Disable if it's the only active category
              className={`category-button ${
                activeCategories[category.name]
                  ? "bg-green-500 text-white border-green-500"
                  : "bg-gray-300 text-gray-700 border-gray-300"
              } ${
                isOnlyActive
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-gray-400"
              }`}
              title={
                isOnlyActive
                  ? "At least one category must remain active."
                  : `Toggle ${category.name}`
              }
              aria-disabled={isOnlyActive}
            >
              {category.name}
            </button>
          );
        })}
      </div>
    );
  };

  /**
   * Loads all flashcards based on active categories.
   * Initializes state properties and shuffles answers.
   */
  useEffect(() => {
    const loadAllFlashcards = async () => {
      try {
        setLoading(true);
        setError(null);
    
        const fetchedCards = [];
    
        // Iterate through each category
        for (const category of CATEGORIES) {
          if (!activeCategories[category.name]) continue; // Skip inactive categories
    
          for (const file of category.files) {
            console.log(`Fetching file: ${file}`); // Log file being fetched
            const response = await fetch(`/data/${file}`);
            if (response.ok) {
              try {
                const data = await response.json();
                console.log(`Successfully parsed JSON from: ${file}`);
    
                if (data.flashcards) {
                  data.flashcards.forEach((card) => {
                    // Assign category and initialize state properties
                    const categorizedCard = {
                      ...card,
                      category: category.name,
                      isAnswered: false, // Initialize as not answered
                      userAnswer: null, // No answer selected yet
                      isCorrect: null, // Correctness not determined yet
                    };
                    // Shuffle answers and update correctIndex
                    const shuffledCard = shuffleAnswersAndUpdateIndex(
                      categorizedCard
                    );
                    fetchedCards.push(shuffledCard);
                  });
                }
              } catch (parseError) {
                console.error(`JSON parsing failed for ${file}:`, parseError);
                setError(
                  `Failed to parse JSON from ${file}: ${parseError.message}`
                );
                return; // Exit early to identify the problematic file
              }
            } else {
              console.error(`Failed to fetch ${file}:`, response.statusText);
              setError(`Failed to fetch ${file}: ${response.statusText}`);
              return; // Exit early to identify the problematic file
            }
          }
        }
    
        // Debug: Log the number of flashcards loaded
        console.log(`Total flashcards loaded: ${fetchedCards.length}`);
        console.log("Categories loaded:", [
          ...new Set(fetchedCards.map((card) => card.category)),
        ]);
    
        // Handle scenario where no flashcards are fetched
        if (fetchedCards.length === 0) {
          console.warn("No flashcards loaded. Please select at least one category.");
          setFlashcards([]);
          resetQuizState();
          return;
        }
    
        // Shuffle the entire set of flashcards
        const shuffledFlashcards = shuffleArray(fetchedCards);
        setFlashcards(shuffledFlashcards);
        resetQuizState();
      } catch (err) {
        console.error("Error loading flashcards:", err);
        setError(`Failed to load flashcards: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    loadAllFlashcards();
  }, [activeCategories]);

  /**
   * Resets the quiz state to initial values.
   */
  const resetQuizState = () => {
    setPassedCount(0);
    setFailedCount(0);
    setCurrentCardIndex(0);
    setShowResults(false);
  };

  /**
   * Handles answer selection for the current flashcard.
   * Updates the flashcard's state and increments pass/fail counts.
   * @param {number} selectedIndex - The index of the selected answer.
   * @param {boolean} isCorrect - Whether the selected answer is correct.
   */
  const handleAnswerSelect = (selectedIndex, isCorrect) => {
    console.log(`Answer selected: Index ${selectedIndex}, Correct: ${isCorrect}`);

    setFlashcards((prevFlashcards) => {
      const updatedFlashcards = [...prevFlashcards];
      const currentCard = { ...updatedFlashcards[currentCardIndex] };
      currentCard.userAnswer = selectedIndex;
      currentCard.isAnswered = true;
      currentCard.isCorrect = isCorrect;
      updatedFlashcards[currentCardIndex] = currentCard;
      console.log(`Updated flashcard:`, currentCard);

      return updatedFlashcards;
    });

    if (isCorrect) {
      setPassedCount((prev) => {
        const newCount = prev + 1;
        console.log(`Passed Count: ${newCount}`);
        return newCount;
      });
    } else {
      setFailedCount((prev) => {
        const newCount = prev + 1;
        console.log(`Failed Count: ${newCount}`);
        return newCount;
      });
    }
  };

  /**
   * Handles navigation to the next flashcard or displays results if at the end.
   */
  const handleNextCard = () => {
    if (currentCardIndex + 1 < flashcards.length) {
      setCurrentCardIndex((prev) => prev + 1);
    } else {
      setShowResults(true);
    }
  };

  /**
   * Displays the results screen.
   */
  const handleShowResults = () => {
    setShowResults(true);
  };

  /**
   * Restarts the quiz by reshuffling all flashcards.
   */
  const handleRestart = () => {
    try {
      const reshuffledCards = shuffleArray(
        flashcards.map((card) => shuffleAnswersAndUpdateIndex(card))
      );

      setFlashcards(reshuffledCards);
      resetQuizState();
    } catch (error) {
      console.error("Error restarting quiz:", error);
      setError("Failed to restart the quiz. Please try again.");
    }
  };

  /**
   * Redoes the failed flashcards by filtering and reshuffling them.
   */
  const handleRedoFailed = () => {
    try {
      const failedCards = flashcards.filter(
        (card) => card.isAnswered && !card.isCorrect
      );

      if (failedCards.length === 0) {
        console.log("No failed cards found:", {
          totalCards: flashcards.length,
          answeredCards: flashcards.filter((card) => card.isAnswered).length,
          failedCards: failedCards.length,
        });
        setError("No failed cards to retry!");
        return;
      }

      const reshuffledFailedCards = shuffleArray(
        failedCards.map((card) => shuffleAnswersAndUpdateIndex(card))
      );

      setFlashcards(reshuffledFailedCards);
      resetQuizState();
      setError(null);
    } catch (error) {
      console.error("Error processing failed cards:", error);
      setError("Failed to process failed cards. Please try again.");
    }
  };

  /**
   * Renders the loading state.
   * @returns {JSX.Element} The loading indicator.
   */
  if (loading) {
    return <div className="text-center p-4">Loading...</div>;
  }

  /**
   * Renders the error state.
   * @returns {JSX.Element} The error message with a retry button.
   */
  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        {/* Toggle Buttons */}
        {renderToggleButtons()}

        <div className="text-center p-4 text-red-500">{error}</div>
        <div className="text-center mt-4">
          <button
            onClick={handleRestart}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  /**
   * Renders a message when no flashcards are available.
   * @returns {JSX.Element} The no flashcards message.
   */
  if (flashcards.length === 0) {
    return (
      <div className="container mx-auto px-4 py-8">
        {/* Toggle Buttons */}
        {renderToggleButtons()}

        <div className="max-w-xl mx-auto bg-white rounded shadow p-6 text-center">
          <h2 className="text-2xl font-bold mb-4">No Flashcards Available</h2>
          <p>Please select at least one category to start the quiz.</p>
        </div>
      </div>
    );
  }

  /**
   * Renders the results view.
   * @returns {JSX.Element} The results screen with statistics and action buttons.
   */
  if (showResults) {
    console.log("Flashcards State:", flashcards); // Add this line
  
    const answeredCount = flashcards.filter((fc) => fc.isAnswered).length;
    const notAnsweredCount = flashcards.length - answeredCount;
    const incorrectCount = failedCount;
    const correctCount = passedCount;
    const failedCardsCount = flashcards.filter(
      (card) => card.isAnswered && !card.isCorrect
    ).length;
  
    console.log(`Results: Answered=${answeredCount}, Total=${flashcards.length}`);

    return (
      <div className="container mx-auto px-4 py-8">
        {/* Toggle Buttons */}
        {renderToggleButtons()}
  
        <div className="max-w-xl mx-auto bg-white rounded shadow p-6 text-center mb-8">
          <h2 className="text-2xl font-bold mb-4">Results</h2>
          <StatBar
            type="results"
            total={flashcards.length}
            correct={correctCount}
            incorrect={incorrectCount}
            notAnswered={notAnsweredCount}
          />
          <div className="flex justify-end space-x-4 mt-6">
            <button
              onClick={handleRestart}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Restart
            </button>
            <button
              onClick={handleRedoFailed}
              disabled={failedCardsCount === 0}
              className={`bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600 ${
                failedCardsCount === 0 ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              Redo Failed ({failedCardsCount})
            </button>
          </div>
        </div>
  
        <div className="max-w-2xl mx-auto">
          {flashcards.map((card, idx) => {
            if (!card.isAnswered) return null;
  
            return (
              <div
                key={card.id}
                className={`mb-4 p-4 rounded shadow ${
                  card.isCorrect ? "bg-green-50" : "bg-red-50"
                }`}
              >
                <h2 className="font-bold text-lg mb-2">
                  {idx + 1}. {card.question}
                </h2>
                <div className="mb-2">
                  {card.answers.map((ans, i) => {
                    let symbol = "";
                    if (i === card.correctIndex) {
                      symbol = "✓";
                    } else if (i === card.userAnswer && !card.isCorrect) {
                      symbol = "✕";
                    }
  
                    const isChosen = i === card.userAnswer;
                    const isCorrectOne = i === card.correctIndex;
                    const colorClass = isCorrectOne
                      ? "bg-green-100"
                      : isChosen
                      ? "bg-red-100"
                      : "bg-white";
  
                    return (
                      <div
                        key={i}
                        className={`mb-1 p-2 rounded border ${colorClass}`}
                      >
                        {symbol && (
                          <span className="font-bold mr-2">{symbol}</span>
                        )}
                        {ans}
                      </div>
                    );
                  })}
                </div>
                <div className="bg-white p-2 rounded mt-2">
                  <p className="text-sm text-gray-800">
                    <strong>Explanation: </strong>
                    {card.explanation}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  /**
   * Renders the main quiz view with the current flashcard.
   * @returns {JSX.Element} The quiz interface.
   */
  const totalCards = flashcards.length;
  const currentCard = flashcards[currentCardIndex];
  const answeredCount = flashcards.filter((fc) => fc.isAnswered).length;
  const notAnsweredCount = totalCards - answeredCount;

  // Variables for button states
  const isLastCard = currentCardIndex + 1 >= flashcards.length;
  const hasAnswered = passedCount + failedCount > 0;

  return (
    <div className="container mx-auto px-4 py-4">
      {/* Toggle Buttons */}
      {renderToggleButtons()}

      <div className="max-w-md mx-auto mb-4">
        <StatBar
          type="answering"
          total={flashcards.length}
          correct={passedCount}
          incorrect={failedCount}
        />
      </div>

      <div className="max-w-2xl mx-auto bg-white p-4 rounded shadow">
        <div className="text-center text-sm text-gray-600 mb-2">
          {currentCardIndex + 1}/{flashcards.length}
        </div>

        <SingleCard
          card={currentCard}
          onAnswerSelect={(selectedIndex, isCorrect) =>
            handleAnswerSelect(selectedIndex, isCorrect)
          }
        />

        {/* Updated Buttons Layout */}
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={handleNextCard}
            disabled={!currentCard.isAnswered}
            className={`px-6 py-2 rounded flex-grow mr-2 ${
              !currentCard.isAnswered
                ? "bg-gray-300 text-white cursor-not-allowed"
                : "bg-blue-500 text-white hover:bg-blue-600"
            }`}
          >
            {isLastCard ? "Finish →" : "Next Card →"}
          </button>
          <button
            onClick={handleShowResults}
            disabled={!hasAnswered}
            className={`bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600 ${
              !hasAnswered ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            Show Results
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleCardPage;
