// src/components/ui/label.js
import React, { forwardRef, useRef, useImperativeHandle, useEffect } from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { cn } from '../../lib/utils';

const Label = forwardRef(({
  className,
  children,
  error,
  required,
  ...props
}, ref) => {
  const labelRef = useRef(null);
  
  useImperativeHandle(ref, () => labelRef.current);

  useEffect(() => {
    if (required && labelRef.current) {
      labelRef.current.setAttribute('aria-required', 'true');
    }
  }, [required]);

  return (
    <LabelPrimitive.Root
      ref={labelRef}
      className={cn(
        "text-sm font-medium leading-none peer-disabled:cursor-not-allowed",
        "peer-disabled:opacity-70 select-none",
        error && "text-red-500",
        className
      )}
      {...props}
    >
      {children}
      {required && (
        <span className="ml-1 text-red-500" aria-hidden="true">
          *
        </span>
      )}
    </LabelPrimitive.Root>
  );
});

Label.displayName = LabelPrimitive.Root.displayName;

export { Label };