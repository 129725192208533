// src/components/magicui/word-pull-up.tsx

"use client";

import React, {
    useRef,
    useMemo,
    type ReactNode,
    type ReactElement,
    type RefObject
  } from "react";
  import { motion, useInView, type HTMLMotionProps, type Variants } from "framer-motion";
  import { cn } from "../../lib/utils";
  
  // 1. Map each valid tag (e.g., 'div', 'h1', etc.) to its corresponding HTMLElement type.
  type ElementRefMap = {
    [K in keyof JSX.IntrinsicElements]: JSX.IntrinsicElements[K] extends React.DetailedHTMLProps<
      React.HTMLAttributes<infer E>,
      infer E
    >
      ? E
      : HTMLElement;
  };
  
  // 2. Provide a generic type T for the HTML tag, defaulting to 'h1'.
  type WordPullUpProps<T extends keyof JSX.IntrinsicElements = "h1"> = {
    children: string;
    className?: string;
    as?: T;
    variants?: Variants;
    wordVariants?: Variants;
    startOnView?: boolean;
  };
  
  // 3. Default container (parent) variants.
  const defaultVariants: Variants = {
    hidden: { opacity: 0, transition: { duration: 0.2 } },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        duration: 0.3,
        ease: "easeOut",
      },
    },
  };
  
  // 4. Default word (child) variants.
  const defaultWordVariants: Variants = {
    hidden: { y: 20, opacity: 0, transition: { duration: 0.2 } },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        damping: 15,
        stiffness: 100,
      },
    },
  };
  
  /**
   * A WordPullUp component that splits children into words
   * and animates each word using Framer Motion. Supports
   * switching between various HTML tags via the `as` prop.
   */
  function WordPullUp<T extends keyof JSX.IntrinsicElements = "h1">({
    children,
    className,
    as,
    variants = defaultVariants,
    wordVariants = defaultWordVariants,
    startOnView = false,
  }: WordPullUpProps<T>): ReactElement {
    if (typeof children !== "string") {
      throw new Error("WordPullUp component requires a string as children.");
    }
  
    // 5. Use a type-aware ref so we don't get DOM type errors.
    const containerRef = useRef<ElementRefMap[T]>(null);
  
    // 6. Fire animation only when visible if startOnView is true.
    const inView = useInView(containerRef as RefObject<HTMLElement>, {
      once: true,
      margin: "-30% 0px -30% 0px",
      amount: 0.2,
    });
  
    // 7. Safely pick a Motion component from the 'motion' object using a cast.
    //    This is necessary because TypeScript doesn't allow bracket indexing
    //    by default on the 'motion' object.
    const MotionComponent = useMemo(() => {
      return (motion as any)[as || "h1"] || motion.h1;
    }, [as]);
  
    // 8. Split the incoming string by spaces and filter out empty values.
    const words = useMemo(() => {
      return children.split(" ").filter(Boolean);
    }, [children]);
  
    // 9. Determine which animation state to use based on inView.
    const animationState = {
      initial: "hidden",
      animate: startOnView && inView ? "show" : "hidden",
      variants,
    };
  
    // 10. Render fallback if no valid motion component is found.
    if (!MotionComponent) {
      console.error(`Invalid motion component tag: ${as}`);
      return <div ref={containerRef as RefObject<HTMLDivElement>}>{children}</div>;
    }
  
    // 11. Render the motion container and animated words.
    return (
      <MotionComponent
        ref={containerRef}
        {...animationState}
        className={cn(
          "text-4xl font-bold leading-[5rem] tracking-[-0.02em]",
          className
        )}
      >
        {words.map((word, i) => (
          <motion.span
            key={`${word}-${i}`}
            variants={wordVariants}
            className="inline-block px-1 whitespace-pre"
            data-word-index={i}
          >
            {word}
          </motion.span>
        ))}
        {words.length === 0 && "\u00A0"}
      </MotionComponent>
    );
  }
  
  WordPullUp.displayName = "WordPullUp";
  export default WordPullUp;
  